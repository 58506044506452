import React, { Fragment } from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Img from "gatsby-image";
import tw from "twin.macro";
import CustomLink from "@components/link";
import { lang, rawName, portableToPlain } from "@utils/helpers";
import { createUrl } from "@utils/caseHelpers";

const Case = styled(motion.div)`
  transition: height .3s, margin-bottom .3s;
  ${tw`
    block max-w-340 mb-24 mx-auto
    sm:mx-0 sm:max-w-280
    md:relative md:max-w-340
    lg:max-w-none lg:w-495 lg:mb-75
    xl:w-624
  `}
  
  &:nth-child(2n) {
    ${tw`md:top-40 lg:top-122`}
  }
  
  &:last-child {
    ${tw`h-400 mb-0 transition-all duration-300 overflow-hidden sm:h-345 md:h-400 lg:h-580 xl:h-695`}
  }
  
  &.lazyload:last-child {
    ${tw`h-0`}
  }
`;

const ImageWrapper = tw.div`mb-16 lg:mb-30 overflow-hidden`;

const Name = tw.div`
  mb-10 text-md leading-25 -tracking-013
  lg:text-2xl lg:leading-60 lg:-tracking-024
`;

const ShortDesc = tw.div`
  flex text-second text-sm leading-20 -tracking-021
  lg:text-md lg:leading-30
`;

const Translation = tw.div`font-pangramBold block mr-35 lg:mr-30 lg:text-lg`;

const PortfolioCase = ({ scroll, width, data }: PortfolioTemplateItem) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [scrollPercentageStart, setScrollPercentageStart] = React.useState(0);
  const [scrollPercentageEnd, setScrollPercentageEnd] = React.useState(1);

  const y = useTransform(scroll, [scrollPercentageStart - 200, scrollPercentageEnd], [180, 0]);
  const opacity = useTransform(scroll, [scrollPercentageStart - 200, scrollPercentageEnd], [0, 1]);
  const scale = useTransform(scroll, [scrollPercentageStart, scrollPercentageEnd + 200], [1.5, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = Ref.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + Ref.current.offsetHeight;

    setScrollPercentageStart(offsetStart);
    setScrollPercentageEnd(offsetEnd);
  });

  return (
    <Case ref={Ref} style={ width >= 1024 && { y, opacity }}>
      <CustomLink to={createUrl(data.slug.current, lang)} type="noIntl">
        <ImageWrapper>
          <motion.div style={ width >= 1024 && { scale }}>
            <Img fluid={data.portfolioImage.asset.fluid} />
          </motion.div>
        </ImageWrapper>
        <Name>
          { data.title }
        </Name>
        <ShortDesc>
          <Translation>{t('Home page:Portfolio short')}</Translation>
          { portableToPlain(data.description[rawName]) }
        </ShortDesc>
      </CustomLink>
    </Case>
  );
};

export default PortfolioCase;
