import React from "react";
import tw from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";

import LoopCase from "@components/portfolio/case";

const Wrapper = tw.div`
  mb-25
  sm:flex sm:flex-wrap sm:justify-between
  md:mb-70
  lg:mb-150
`;

const Projects = ({ scroll, width }: { scroll: any, width: number }) => {
  const { allSanityCases } = useStaticQuery(graphql`
    query {
      allSanityCases(sort: { fields: order, order: ASC }) {
        nodes {
          order
          title
          slug { current }
          description { _rawEn, _rawPl }
          portfolioImage { asset { fluid { ...GatsbySanityImageFluid }}}
        }
      }
    }
  `);

  return (
    <Wrapper>
      {allSanityCases.nodes.map((node) => (
        <LoopCase
          key={node.title} 
          scroll={scroll}
          width={width}
          data={node}
        />
      ))}
    </Wrapper>
  )
};

export default Projects;
