import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary"
import ArrowRed from "@components/arrows/red"

const Wrapper = tw.div`
  relative flex flex-wrap ml-40 mr-20 mb-35
  sm:ml-20 sm:mb-50
  md:ml-0
  lg:mt-175 lg:mb-155
`;

const Header = styled(motion.div)`
  ${tw`
    w-full mb-10 text-xl leading-33 -tracking-016
    md:mt-140 md:ml-10 md:mb-60 md:text-2xl
    lg:order-2 lg:relative lg:top-120 lg:w-1/6 lg:my-0 lg:text-4xl lg:leading-60 lg:-tracking-028
    lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const Character = tw(motion.div)`
  font-pangramBold w-30 text-sm text-second leading-32 -tracking-021
  lg:order-1 lg:w-1/12 lg:mt-9 lg:text-lg lg:opacity-0 lg:leading-32 lg:-tracking-03
`;

const Description = tw(motion.div)`
  w-8/12 text-second leading-24 -tracking-024
  md:w-150 md:text-sm
  lg:order-3 lg:w-5/12 lg:ml-auto lg:text-2xl lg:leading-50 lg:opacity-0
`;

const OpacityMask = styled(motion.div)`
  ${tw`lg:opacity-0`}

  ${down('md')} {
    transform: none !important;
  }
`;

const Illustration = tw(Img)`
  absolute! hidden
  sm:block sm:top-0 sm:right-70 sm:w-260
  md:w-350
  lg:top-600 lg:-left-380 lg:w-435 lg:z-1
  xl:top-550 xl:-left-235
`;

const Arrow = styled.div`
  ${tw`
    absolute! top-500 left-125
    sm:left-100
    md:top-480
    lg:top-580
    xl:top-1050 xl:left-20
  `}  

  svg {
    ${tw`w-140 transform rotate-0 -scale-x-100 xl:w-220`}
  }
`;

const ArrowCircle = tw.div`
  absolute! hidden
  md:block md:top-180 md:-right-280
  lg:top-80 lg:-right-240
`;

const PortfolioHeader = ({ width }: { width: Number }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Header
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          y: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] },
          opacity: { duration: .458, delay: .65, ease: 'linear' },
          rotate: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { y: -120, opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        {t('Portfolio:Header')}
      </Header>
      <Character
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{ duration: .6, delay: .8, ease: [.88, .14, .12, .86] }}
      >
        {t('Portfolio:Character')}
      </Character>
      <Description
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{ duration: .6, delay: 1, ease: [.88, .14, .12, .86] }}
      >
        {t('Portfolio:See portfolio')}
      </Description>
      <OpacityMask
        animate={ width >= 1024 && { x: 480, y: -720, opacity: 1 }}
        transition={{
          opacity: { duration: .675, ease: 'linear' },
          default: { duration: 1.625, delay: .675, ease: [.88, .14, 0, .99] }
        }}
      >
        <Illustration fluid={data.illustration.childImageSharp.fluid} />
      </OpacityMask>
      <Arrow>
        <ArrowPrimary />
      </Arrow>
      <ArrowCircle>
        <ArrowRed />
      </ArrowCircle>
    </Wrapper>
  );
};

export default PortfolioHeader;
