import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll, motion } from "framer-motion";

import useWindowSize from "@utils/useWindowSize";

import Layout from '@layouts/index';
import SEO from "@components/seo";
import Header from "@components/portfolio/header";
import Projects from "@components/portfolio/projects";
import CTA from "@components/cta";

const PortfolioPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Portfolio' })}
        description={intl.formatMessage({ id: 'SEO.Description.Portfolio' })}
      />
      <Header width={ width } />
      <motion.div
        className="lg:opacity-0"
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{
          duration: .6,
          delay: 1.7,
          ease: [0.13, -0.22, 1, 1.02]
        }}
      >
        <Projects width={ width } scroll={ scrollY } />
      </motion.div>
      <CTA width={ width } scroll={ scrollY } />
    </Layout>
  );
};

export default PortfolioPage;
